import {
  IDefaultTableColumnConfig,
  ITradeInOverviewBillboardConfig,
  ITradeInOverviewConfig,
  IDualAxisLineGraphConfig,

  IMultiLevelDualAxisLineGraphConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class TradeInOverviewConfig implements ITradeInOverviewConfig {
  public reportTitle = 'Trade-In Overview';
  public chartSeriesColors = ['#343a40', '#a1a1a1', '#666666'];
  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];
  public billboards: ITradeInOverviewBillboardConfig[] = [{
    title: 'Visits With Impressions',
    subtitle: null,
    iconClass: 'fa-users fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'visitsWithImpressions',
    metricMOMPropertyName: 'visitsWithImpressionsMOM',
    metricYOYPropertyName: 'visitsWithImpressionsYOY',
    metricPreviousMonthPropertyName: 'visitsWithImpressionsPreviousMonth',
    metricPreviousMTDPropertyName: 'visitsWithImpressionsPreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    trendArrowDrivingPropertyName: 'visitsWithImpressionsMOM',
    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
    helpTextKey: 'TradeInOverviewVisitsWithImpressionsBillboardHelp',
    helpTextTitle: 'Visits With Impressions',
  }, {
    title: 'Trade-In Start Rate',
    subtitle: null,
    iconClass: 'fa-calculator fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'startRate',
    metricMOMPropertyName: 'startRateMOM',
    metricYOYPropertyName: 'tradeInStartRateYOY',
    metricPreviousMonthPropertyName: 'tradeInStartRatePreviousMonth',
    metricPreviousMTDPropertyName: 'tradeInStartRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    trendArrowDrivingPropertyName: 'tradeInStartRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'TradeInOverviewTradeInStartRateBillboardHelp',
    helpTextTitle: 'Trade-In Start Rate',
  }, {
    title: 'Form Submission Rate',
    subtitle: null,
    iconClass: 'fa-user-circle fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'formSubmissionRate',
    metricMOMPropertyName: 'formSubmissionRateMOM',
    metricYOYPropertyName: 'formSubmissionRateYOY',
    metricPreviousMonthPropertyName: 'formSubmissionRatePreviousMonth',
    metricPreviousMTDPropertyName: 'formSubmissionRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    trendArrowDrivingPropertyName: 'formSubmissionRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'TradeInOverviewFormSubmissionRateBillboardHelp',
    helpTextTitle: 'Form Submission Rate',
  }, {
    title: 'Completion Rate',
    subtitle: null,
    iconClass: 'fa-check-square-o fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'completionRate',
    metricMOMPropertyName: 'completionRateMOM',
    metricYOYPropertyName: 'completionRateYOY',
    metricPreviousMonthPropertyName: 'completionRatePreviousMonth',
    metricPreviousMTDPropertyName: 'completionRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    trendArrowDrivingPropertyName: 'completionRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'TradeInOverviewCompletionRateBillboardHelp',
    helpTextTitle: 'Compleition Rate',
  }];
  public kpiSheetOptions = {
    overview: [{
      name: 'providerId',
      label: 'Provider',
      isHeader: true
    }, {
      name: 'visitsWithImpressions',
      label: 'Visits With Impressions',
    }, {
      name: 'startRate',
      label: 'Trade-In Start Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'vehicleIndicatedRate',
      label: 'Vehicle Selection Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'formSubmissionRate',
      label: 'Form Submission Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'completionRate',
      label: 'Completion Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }]
  };
  public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      {
        id: Enums.tradeInMetrics.impressions.metricId,
        displayName: Enums.tradeInMetrics.impressions.name,
        propertyName: Enums.tradeInMetrics.impressions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.visitsWithImpressions.metricId,
        displayName: Enums.tradeInMetrics.visitsWithImpressions.name,
        propertyName: Enums.tradeInMetrics.visitsWithImpressions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.startRate.metricId,
        displayName: Enums.tradeInMetrics.startRate.name,
        propertyName: Enums.tradeInMetrics.startRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.tradeInMetrics.vehicleIndicateds.metricId,
        displayName: Enums.tradeInMetrics.vehicleIndicateds.name,
        propertyName: Enums.tradeInMetrics.vehicleIndicateds.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.vehicleIndicatedRate.metricId,
        displayName: Enums.tradeInMetrics.vehicleIndicatedRate.name,
        propertyName: Enums.tradeInMetrics.vehicleIndicatedRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.tradeInMetrics.formInitiations.metricId,
        displayName: Enums.tradeInMetrics.formInitiations.name,
        propertyName: Enums.tradeInMetrics.formInitiations.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.formInitiationRate.metricId,
        displayName: Enums.tradeInMetrics.formInitiationRate.name,
        propertyName: Enums.tradeInMetrics.formInitiationRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.tradeInMetrics.formSubmissions.metricId,
        displayName: Enums.tradeInMetrics.formSubmissions.name,
        propertyName: Enums.tradeInMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.formSubmissionRate.metricId,
        displayName: Enums.tradeInMetrics.formSubmissionRate.name,
        propertyName: Enums.tradeInMetrics.formSubmissionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.tradeInMetrics.appointments.metricId,
        displayName: Enums.tradeInMetrics.appointments.name,
        propertyName: Enums.tradeInMetrics.appointments.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.appointmentRate.metricId,
        displayName: Enums.tradeInMetrics.appointmentRate.name,
        propertyName: Enums.tradeInMetrics.appointmentRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.tradeInMetrics.completions.metricId,
        displayName: Enums.tradeInMetrics.completions.name,
        propertyName: Enums.tradeInMetrics.completions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.completionRate.metricId,
        displayName: Enums.tradeInMetrics.completionRate.name,
        propertyName: Enums.tradeInMetrics.completionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }
    ],
    childMetrics: [
      {
        id: Enums.chatProviders.allProviders.providerId,
        displayName: Enums.chatProviders.allProviders.displayName,
        propertyName: Enums.chatProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 5,
        displayName: 'Dealer eProcess',
        propertyName: 'Dealer eProcess',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 14,
        displayName: 'TradePending',
        propertyName: 'TradePending',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 20,
        displayName: 'Edmunds',
        propertyName: 'Edmunds',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 33,
        displayName: 'Car and Driver',
        propertyName: 'Car and Driver',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 34,
        displayName: 'Kelly Blue Book',
        propertyName: 'Kelly Blue Book',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 35,
        displayName: 'TradeIn Valet',
        propertyName: 'TradeIn Valet',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 36,
        displayName: 'TradeVue',
        propertyName: 'TradeVue',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      }
    ],
    defaultChildrenIds: [Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId],
    defaultParentIds: [Enums.tradeInMetrics.visitsWithImpressions.metricId, Enums.tradeInMetrics.vehicleIndicateds.metricId],
    metricOneColorHexCode: '#343a40',
    metricTwoColorHexCode: '#afb1b2'
  };
  public dealerTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
      // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
    }, {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },

    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Impressions Prev. Month',
      columnDef: 'impressionsPrevMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Impressions MOM',
      columnDef: 'impressionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Visits With Impressions',
      columnDef: 'visitsWithImpressions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Visits With Impressions Prev. Month',
      columnDef: 'visitsWithImpressionsPrevMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Visits With Impressions MOM',
      columnDef: 'visitsWithImpressionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Trade-Ins Started',
      columnDef: 'starts',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Trade-Ins Started Prev. Month',
      columnDef: 'startsPrevMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Trade-Ins Started MOM',
      columnDef: 'startsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Trade-In Start Rate',
      columnDef: 'startRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Start Rate Prev. Month',
      columnDef: 'startRatePrevMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Start Rate MOM',
      columnDef: 'startRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    // vehicle indicateds/selections
    {
      show: true,
      header: 'Vehicle Selections',
      columnDef: 'vehicleIndicateds',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Vehicle Selections Prev. Month',
      columnDef: 'vehicleIndicatedsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Vehicle Selections MOM',
      columnDef: 'vehicleIndicatedsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Vehicle Selection Rate',
      columnDef: 'vehicleIndicatedRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Vehicle Selection Rate Prev. Month',
      columnDef: 'vehicleIndicatedRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Vehicle Selection Rate MOM',
      columnDef: 'vehicleIndicatedRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // form initiations
    {
      show: true,
      header: 'Form Initiations',
      columnDef: 'formInitiations',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Form Initiations Prev. Month',
      columnDef: 'formInitiationsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Form Initiations MOM',
      columnDef: 'formInitiationsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Form Initiation Rate',
      columnDef: 'formInitiationRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Form Initiation Rate Prev. Month',
      columnDef: 'formInitiationRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Form Initiation Rate MOM',
      columnDef: 'formInitiationRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // form submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Form Submissions Prev. Month',
      columnDef: 'formSubmissionsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Form Submissions MOM',
      columnDef: 'formSubmissionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Form Submission Rate',
      columnDef: 'formSubmissionRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Form Submission Rate Prev. Month',
      columnDef: 'formSubmissionRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Form Submission Rate MOM',
      columnDef: 'formSubmissionRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // appointments
    {
      show: true,
      header: 'Appointments',
      columnDef: 'appointments',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Appointments Prev. Month',
      columnDef: 'appointmentsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Appointments MOM',
      columnDef: 'appointmentsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointment Rate Prev. Month',
      columnDef: 'appointmentRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointment Rate MOM',
      columnDef: 'appointmentRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // completions
    {
      show: true,
      header: 'Completions',
      columnDef: 'completions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Completions Prev. Month',
      columnDef: 'completionsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Completions MOM',
      columnDef: 'completionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Completion Rate',
      columnDef: 'completionRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Completion Rate Prev. Month',
      columnDef: 'completionRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Completion Rate MOM',
      columnDef: 'completionRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
  ];
}
