import { IWebsiteProviderScorecardConfig, IWebsiteProviderScorecardBillboardConfig } from '../../../../_shared/models/models-index';
import { IMetricType, Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteProviderScorecardConfig implements IWebsiteProviderScorecardConfig {
    public reportTitle = 'Provider Scorecard';

    public scorecardMetrics: IWebsiteProviderScorecardBillboardConfig[] = [
        {
            metric: Enums.websiteMetrics.dealerEnrollments,
            metricPosition: 1,
            filterPosition: 1,
            metricFormatKey: constants.formatKeys.localeStringOrNa
        },
        {
            metric: Enums.websiteMetrics.avgVisitors,
            metricPosition: 2,
            filterPosition: 4,
            metricFormatKey: constants.formatKeys.localeStringOrNa
        },
        {
            metric: Enums.websiteMetrics.avgEngagementRate,
            metricPosition: 3,
            filterPosition: 3,
            metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
        },
        {
            metric: Enums.websiteMetrics.avgActions,
            metricPosition: 4,
            filterPosition: 2,
            metricFormatKey: constants.formatKeys.localeStringOrNa
        },
    ];
}
