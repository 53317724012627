import { Injectable } from '@angular/core';
import { SharedTranslationConfig } from '../../../_config/translation/shared/shared.translation.config';

@Injectable()
export class SharedTranslationService {
  sharedTranslations = new SharedTranslationConfig;
  appSectionTranslations: any;
  navLabelTranslations: any;
  constructor() { }
  getLabelTranslation(value: string, locale?: string): string {
    if (locale == 'en-US') locale = 'en';
    if (!locale || !value 
      || !!!this.sharedTranslations.labels // Return default value if we don't have translations enabled
      || !!!this.sharedTranslations.labels[locale]
    ) {
      return value;
    }

    const key = value.toLowerCase().replace(/[^A-Z0-9]+/ig, '');

    return this.sharedTranslations.labels[locale][key] ?? value;
  }
}

