import { IOrgLookupType } from '../../_shared/enums/enums';
import { IOrgConfig } from '../../_shared/models/models-index';

export class OrgConfig implements IOrgConfig {

    orgLookupTypes: Record<string, IOrgLookupType> = {
        'national': { name: 'National', orgLookupTypeId: 1, default: true, orgEntityType: 'national' },
        'dealerGroups': { name: 'Dealer Groups', orgEntityType: 'orgcode1', orgLookupTypeId: 4 }
    };

    public nationalOrgLookupTypeIds: number[] = [1];
    public lmaOrgLookupTypeIds: number[] = [2];
    public virtual20OrgLookupTypeIds: number[] = [3];
    public dealerGroupOrgLookupTypeIds: number[] = [4];

    orgEntityType: Map<string, string> = new Map([
        ['orgcode2', 'orgcode2'],
        ['orgcode1', 'orgcode1'],
        ['dealer', 'dealer'],
    ]);

    entityTypeName: Map<string, string> = new Map([
        ['orgcode2', 'Region'],
        ['orgcode1', 'District'],
        ['dealer', 'Dealer'],
        // ['virtual20', 'Virtual 20'],
    ]);
}
