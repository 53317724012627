
import {
  ISalesForecastingConfig,
  ISalesForecastingBillboardConfig,
  IDefaultTableColumnConfig,
  ISalesForecastingModelPerformanceConfig,
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class SalesForecastingConfig implements ISalesForecastingConfig {
  public reportTitle: string = 'Sales Overview (BETA)';
  public useDefaultComparisonColumns: boolean;
  public chartSeriesColors: string[];

  public billboards: ISalesForecastingBillboardConfig[] = [
    {
      title: 'Total Sales',
      iconClass: 'fa-car fa-primary',
      metricCurrentPropertyName: 'totalSales',
      trendArrowDrivingPropertyName: 'totalSalesMOM',
      trendArrowInverted: false,
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      footerOneLeftFormatterKey: constants.formatKeys.percentage,
      footerOneRightFormatterKey: constants.formatKeys.percentage,
      metricPreviousMtdPropertyName: 'totalSalesPreviousMTD',
      metricPreviousMonthPropertyName: 'totalSalesPreviousMonth',
      footerOneLeftValuePropertyName: 'totalSalesMOM',
      footerOneLeftLabel: 'MOM',
      footerOneRightValuePropertyName: 'totalSalesYOY',
      footerOneRightLabel: 'YOY',
      helpTextKey: 'SalesForecastingSummaryTotalSalesHelp',
      helpTextTitle: 'Total Sales',
      topPropertyName: 'totalSalesNationalAverage',
      topPropertyLabel: 'Dealer Avg.',
      middlePropertyName: 'totalSalesTop10Percent',
      middlePropertyLabel: 'Top 10%',
      bottomPropertyName: 'totalSalesBottom10Percent',
      bottomPropertyLabel: 'Bottom 10%',
    },
    {
      title: 'New Sales',
      iconClass: 'fa-car fa-primary',
      metricCurrentPropertyName: 'newSales',
      trendArrowDrivingPropertyName: 'newSalesMOM',
      trendArrowInverted: false,
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      footerOneLeftFormatterKey: constants.formatKeys.percentage,
      footerOneRightFormatterKey: constants.formatKeys.percentage,
      metricPreviousMtdPropertyName: 'newSalesPreviousMTD',
      metricPreviousMonthPropertyName: 'newSalesPreviousMonth',
      footerOneLeftValuePropertyName: 'newSalesMOM',
      footerOneLeftLabel: 'MOM',
      footerOneRightValuePropertyName: 'newSalesYOY',
      footerOneRightLabel: 'YOY',
      helpTextKey: 'SalesForecastingSummaryNewSalesHelp',
      helpTextTitle: 'New Sales',
      topPropertyName: 'newSalesNationalAverage',
      topPropertyLabel: 'Dealer Avg.',
      middlePropertyName: 'newSalesTop10Percent',
      middlePropertyLabel: 'Top 10%',
      bottomPropertyName: 'newSalesBottom10Percent',
      bottomPropertyLabel: 'Bottom 10%',
    },
    {

      title: 'CPO Sales',
      iconClass: 'fa-car fa-primary',
      metricCurrentPropertyName: 'cpoSales',
      trendArrowDrivingPropertyName: 'cpoSalesMOM',
      trendArrowInverted: false,
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      footerOneLeftFormatterKey: constants.formatKeys.percentage,
      footerOneRightFormatterKey: constants.formatKeys.percentage,
      metricPreviousMtdPropertyName: 'cpoSalesPreviousMTD',
      metricPreviousMonthPropertyName: 'cpoSalesPreviousMonth',
      footerOneLeftValuePropertyName: 'cpoSalesMOM',
      footerOneLeftLabel: 'MOM',
      footerOneRightValuePropertyName: 'cpoSalesYOY',
      footerOneRightLabel: 'YOY',
      helpTextKey: 'SalesForecastingSummaryCPOSalesHelp',
      helpTextTitle: 'CPO Sales',
      topPropertyName: 'cpoSalesNationalAverage',
      topPropertyLabel: 'Dealer Avg.',
      middlePropertyName: 'cpoSalesTop10Percent',
      middlePropertyLabel: 'Top 10%',
      bottomPropertyName: 'cpoSalesBottom10Percent',
      bottomPropertyLabel: 'Bottom 10%',
    },
  ];
  public billboardsDealerLevel: ISalesForecastingBillboardConfig[] = [
    {
      title: 'Total Sales',
      iconClass: 'fa-car fa-primary',
      metricCurrentPropertyName: 'totalSales',
      trendArrowDrivingPropertyName: 'totalSalesMOM',
      trendArrowInverted: false,
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      footerOneLeftFormatterKey: constants.formatKeys.percentage,
      footerOneRightFormatterKey: constants.formatKeys.percentage,
      metricPreviousMtdPropertyName: 'totalSalesPreviousMTD',
      metricPreviousMonthPropertyName: 'totalSalesPreviousMonth',
      footerOneLeftValuePropertyName: 'totalSalesMOM',
      footerOneLeftLabel: 'MOM',
      footerOneRightValuePropertyName: 'totalSalesYOY',
      footerOneRightLabel: 'YOY',
      helpTextKey: 'SalesForecastingSummaryTotalSalesHelp',
      helpTextTitle: 'Total Sales',
      topPropertyName: 'totalSalesNationalAverage',
      topPropertyLabel: 'National Avg.',
      middlePropertyName: 'totalSalesOrg2Average',
      middlePropertyLabel: 'Region Avg.',
      bottomPropertyName: 'totalSalesOrg1Average',
      bottomPropertyLabel: 'District Avg.',
    },
    {
      title: 'New Sales',
      iconClass: 'fa-car fa-primary',
      metricCurrentPropertyName: 'newSales',
      trendArrowDrivingPropertyName: 'newSalesMOM',
      trendArrowInverted: false,
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      footerOneLeftFormatterKey: constants.formatKeys.percentage,
      footerOneRightFormatterKey: constants.formatKeys.percentage,
      metricPreviousMtdPropertyName: 'newSalesPreviousMTD',
      metricPreviousMonthPropertyName: 'newSalesPreviousMonth',
      footerOneLeftValuePropertyName: 'newSalesMOM',
      footerOneLeftLabel: 'MOM',
      footerOneRightValuePropertyName: 'newSalesYOY',
      footerOneRightLabel: 'YOY',
      helpTextKey: 'SalesForecastingSummaryNewSalesHelp',
      helpTextTitle: 'New Sales',
      topPropertyName: 'newSalesNationalAverage',
      topPropertyLabel: 'National Avg.',
      middlePropertyName: 'newSalesOrg2Average',
      middlePropertyLabel: 'Region Avg.',
      bottomPropertyName: 'newSalesOrg1Average',
      bottomPropertyLabel: 'District Avg.',
    },
    {
      title: 'CPO Sales',
      iconClass: 'fa-car fa-primary',
      metricCurrentPropertyName: 'cpoSales',
      trendArrowDrivingPropertyName: 'cpoSalesMOM',
      trendArrowInverted: false,
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      footerOneLeftFormatterKey: constants.formatKeys.percentage,
      footerOneRightFormatterKey: constants.formatKeys.percentage,
      metricPreviousMtdPropertyName: 'cpoSalesPreviousMTD',
      metricPreviousMonthPropertyName: 'cpoSalesPreviousMonth',
      footerOneLeftValuePropertyName: 'cpoSalesMOM',
      footerOneLeftLabel: 'MOM',
      footerOneRightValuePropertyName: 'cpoSalesYOY',
      footerOneRightLabel: 'YOY',
      helpTextKey: 'SalesForecastingSummaryCPOSalesHelp',
      helpTextTitle: 'CPO Sales',
      topPropertyName: 'cpoSalesNationalAverage',
      topPropertyLabel: 'National Avg.',
      middlePropertyName: 'cpoSalesOrg2Average',
      middlePropertyLabel: 'Region Avg.',
      bottomPropertyName: 'cpoSalesOrg1Average',
      bottomPropertyLabel: 'District Avg.',
    },
  ];

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      hasComparisonMetrics: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      hasComparisonMetrics: false,
      metricFormatKey: constants.formatKeys.entityDisplayName
    },
    // Total Sales
    {
      show: true,
      header: 'Total Sales',
      columnDef: 'totalSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    //Projected Sales
    {
      show: true,
      header: 'Projected Sales',
      columnDef: 'totalForecastedSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.roundToIntegerLocaleString
    },
    //New Sales
    {
      show: true,
      header: 'New Sales',
      columnDef: 'newSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    //CPO Sales
    {
      show: true,
      header: 'CPO Sales',
      columnDef: 'cpoSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    //Unique Visitors
    {
      show: true,
      header: 'Unique Visits',
      columnDef: 'uniqueVisits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    //VLPs
    {
      show: true,
      header: 'VLPs',
      columnDef: 'vlpViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    //VDPs
    {
      show: true,
      header: 'VDPs',
      columnDef: 'vdpViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    //Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
  ];

  public modelPerformanceBarMetricName: string = 'totalSales';

  public modelPerformanceMetrics: ISalesForecastingModelPerformanceConfig[] = [

    {
      displayName: 'New Sales',
      metricName: 'newSales',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      includeForCurrentMonth: true,
      includeForHistoricalMonth: true
    },
    {
      displayName: 'CPO Sales',
      metricName: 'cpoSales',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      includeForCurrentMonth: true,
      includeForHistoricalMonth: true
    },
    {
      displayName: 'Total Sales',
      metricName: 'totalSales',
      metricFormatKey: constants.formatKeys.localeStringOrNa,
      includeForCurrentMonth: true,
      includeForHistoricalMonth: true
    },
    {
      displayName: 'Projected Sales',
      metricName: 'totalForecastedSales',
      metricFormatKey: constants.formatKeys.roundToIntegerLocaleString,
      includeForCurrentMonth: true,
      includeForHistoricalMonth: false
    },
  ]
}
