import * as Models from '../../models/models-index';
import { Filter, FilterTypes } from '../filter.model';
import { IFilterTreeNode } from '../../sd-filter-panel-module/types/types';
import { IFilterModel } from '../../models/models-index';
import { IFacetedFilterDataItem, IFacetedFilterSelection, ISelectionInfo } from '../../hy-filter-panel-module/types';

export class FilterOptions {
  includeAdvanced: boolean;
  includeLookback: boolean;
  includeMOM: boolean;
  includeTrend: boolean;
  includeYOY: boolean;
}

export class ReportFilters {
  reportName: string;
  filters: Filter[];
}

export class ReportViewFilters {
  reportName: string;
  filters: Models.ReportViewFilter[];
}

export class ReportViewFilterSelections {
  reportName: string;
  selections: ReportViewFilterSelection[];
}

export class ReportViewFilterSelection {
  name: string;
  label: string;
  item: IFacetedFilterSelection;
  locked: boolean;
  lockable: boolean;
  removeable: boolean;
  visible: boolean = true;
}



export const buildReportViewFilterSelection: (
  name: string,
  label: string,
  item: IFacetedFilterSelection,
  locked: boolean,
  lockable: boolean,
  removeable: boolean,
  visible: boolean
) => ReportViewFilterSelection = (
  name: string,
  label: string,
  item: IFacetedFilterSelection,
  locked: boolean,
  lockable: boolean,
  removeable: boolean,
  visible: boolean
) => {
  return {
    name,
    label,
    item,
    locked,
    lockable,
    removeable,
    visible
  };
};

export interface FilterState {
  filters: ReportFilters[];
  reportViewFilters: ReportViewFilters[];
  reportViewFilterSelections: ReportViewFilterSelections[];
  lockedFilters: Filter[];
  lockedReportViewFilters: ReportViewFilterSelection[];
  explicitUnlockedFilters: string[];
  options: FilterOptions;
}

export const initialFilterState: FilterState = {
  filters: [],
  reportViewFilters: [],
  reportViewFilterSelections: [],
  lockedFilters: [],
  lockedReportViewFilters: [],
  explicitUnlockedFilters: [],
  options: {
    includeAdvanced: false,
    includeLookback: false,
    includeMOM: false,
    includeTrend: false,
    includeYOY: false
   }
};
