import { Pipe, PipeTransform, Injectable, DestroyRef } from '@angular/core';
import { SharedTranslationService } from '../locale/translation/shared-translation.service';
import { LocaleService, TranslationService } from '../services/services-index';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subscription, combineLatest, exhaustMap, filter, map, of, switchMap, tap } from 'rxjs';
import { AppSelectors } from '../../_store/selector-types';
import { AppState } from '../../_store/app-state.model';
import { Store } from '@ngrx/store';

@Injectable()
@Pipe({ name: 'translate', pure: true })
export class TranslateBasePipe implements PipeTransform {
  private currentLocale: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private destroyRef: DestroyRef,
    private sharedTranslationService: SharedTranslationService,
    private localeService: LocaleService,
    private translationService: TranslationService,
    private store$: Store<AppState>) {
    this.subscriptions.push(
      this.localeService.locale$
        .pipe(takeUntilDestroyed())
        .subscribe(locale => {
          this.currentLocale = locale;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  
  transform(val: any, locale?: string): Observable<string> {
    // Use the provided locale if available, otherwise use the current locale from the service
    let cultureCode = locale || this.currentLocale || "en-US";
   
    if (cultureCode.toLowerCase() === 'en')
      cultureCode = 'en-US'; // fix for en culture code due to legacy things

    // we need to do this because we're using a javascript dictionary to store the translations
    const value = val.toLowerCase().replace(/[^A-Z0-9]+/ig, '');

    return combineLatest([
      // v5 is in the store, so kick off a fetch request && map it to the value
      this.store$.select(AppSelectors.selectConfigState).pipe(
        filter(config => !!config),
        map(config => {
          return config.resourceStrings?.[cultureCode] ? config.resourceStrings[cultureCode][value] : null
        })
      ),
      // v4 is static, so we need to turn it into an observable
      of(this.sharedTranslationService.getLabelTranslation(val, cultureCode))
    ]).pipe(
      //tap(([v5, v4]) => console.log(value, " | ", v5, " | ", v4, " | ", v5 || v4)),
      map(([v5, v4]) => {
        // Try v5, then v4, then fallback on whatever was passed in
        return v5 || v4 || val;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
