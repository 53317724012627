import { IDealerResourceCategoryConfig, IDealerResourcesConfig } from '../../../../_shared/models/models-index';

export class DealerResourcesConfig implements IDealerResourcesConfig {
  public reportTitle = 'Dealer Resources';
  public disclaimer = '';
  public resourceCategories: IDealerResourceCategoryConfig[] = [
      {
        category: 'Provider Scorecard',
        resourceConfig: [
            {
                label: 'Provider Scorecard 2023 Q1',
                imgUrl: '../../assets/images/DealerResources/Lexus/ProviderScorecard/Lexus_Provider_Scorecard_(Dealer)_2023_q1.jpg',
                downloadUrl: 'https://lexusprod.blob.core.windows.net/dealer-resources/provider-scorecard/Lexus_Provider_Scorecard_(Dealer)_2023_q1.pdf'
            },
            {
                label: 'Provider Scorecard 2022 Q4',
                imgUrl: '../../assets/images/DealerResources/Lexus/ProviderScorecard/Lexus_Provider_Scorecard_(Dealer)_2022_q4.jpg',
                downloadUrl: 'https://lexusprod.blob.core.windows.net/dealer-resources/provider-scorecard/Lexus_Provider_Scorecard_(Dealer)_2022_q4.pdf'
            },
            {
                label: 'Provider Scorecard 2022 Q3',
                imgUrl: '../../assets/images/DealerResources/Lexus/ProviderScorecard/Lexus_Provider_Scorecard_(Dealer)_2022_q3.jpg',
                downloadUrl: 'https://lexusprod.blob.core.windows.net/dealer-resources/provider-scorecard/Lexus_Provider_Scorecard_(Dealer)_2022_q3.pdf'
            },
            {
                label: 'Provider Scorecard 2022 Q2',
                imgUrl: '../../assets/images/DealerResources/Lexus/ProviderScorecard/Lexus_Provider_Scorecard_(Dealer)_2022_q2.jpg',
                downloadUrl: 'https://lexusprod.blob.core.windows.net/dealer-resources/provider-scorecard/Lexus_Provider_Scorecard_(Dealer)_2022_q2.pdf'
            },
            {
                label: 'Provider Scorecard 2022 Q1',
                imgUrl: '../../assets/images/DealerResources/Lexus/ProviderScorecard/Lexus_Provider_Scorecard_(Dealer)_2022_q1.jpg',
                downloadUrl: 'https://lexusprod.blob.core.windows.net/dealer-resources/provider-scorecard/Lexus_Provider_Scorecard_(Dealer)_2022_q1.pdf'
            },
            {
                label: 'Provider Scorecard 2021 Q4',
                imgUrl: '../../assets/images/DealerResources/Lexus/ProviderScorecard/Lexus_Provider_Scorecard_(Dealer)_2021_q4.jpg',
                downloadUrl: 'https://lexusprod.blob.core.windows.net/dealer-resources/provider-scorecard/Lexus_Provider_Scorecard_(Dealer)_2021_q4.pdf'
            }
          ]
      },
      {
        category: 'Dealer Resources',
        resourceConfig: [
            {
                label: 'Monroney Labels',
                imgUrl: '../../assets/images/DealerResources/Lexus/DealerResources/Lexus_Monroney.jpg',
                downloadUrl: 'https://lexusprod.blob.core.windows.net/dealer-resources/dealer-resources/Monroney_Label.pdf'
            },
            {
              label: 'Mystery Shop',
              imgUrl: '../../assets/images/DealerResources/Lexus/DealerResources/Lexus_Sales_Mystery_Shop_Guide_FEB_2022.jpg',
              downloadUrl: 'https://lexusprod.blob.core.windows.net/dealer-resources/dealer-resources/Lexus_Sales_Mystery_Shop_Guide_FEB_2022.pdf'
            }
          ]
      }
    ];
}
