

import {
  IWebsiteOverviewBillboardConfig,
  IWebsiteOverviewConfig,
  IDefaultTableColumnConfig,
  IWebsiteOverviewKpiConfig,
  ICardHeaderConfig } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteOverviewConfig implements IWebsiteOverviewConfig {
  public reportTitle = 'Website Overview';
  public benchmarkTitle = 'Dealer Performance';
  public useNewMetricCutoffDate = true;
  public useDefaultComparisonColumns = true;
  public newMetricCutoffDate = new Date(2019, 0, 1);
  public showPieChart: boolean = true;
  public chartSeriesColors = ['#5a5a5a', '#555555'];
  public referrerTypeColors = ['#5a5a5a'];
  public referrerQualityColors = ['#999999', '#777777', '#555555', '#111111' ];
  public deviceTypeColors = ['#343a40', '#a1a1a1', '#666666'];

  public referrerQualityCardHeader: ICardHeaderConfig = {
    title: 'Referrer Quality',
    iconClass: 'fa-trophy',
    helpTextKey: constants.helpTextKeys.websiteOverviewReferrerQualityHelp,
    helpTextTitle: 'Referrer Quality',
  };
  public referrerTypeCardHeader: ICardHeaderConfig = {
    title: 'Referrer Type',
    iconClass: 'fa-align-left',
    helpTextKey: constants.helpTextKeys.websiteOverviewReferrerTypeHelp,
    helpTextTitle: 'Referrer Type',
  };
  public trafficByDeviceTypeHeader: ICardHeaderConfig = {
    title: 'Traffic By Device',
    iconClass: 'fa-mobile',
    helpTextKey: constants.helpTextKeys.websiteOverviewTrafficByDeviceTypeHelp,
    helpTextTitle: 'Traffic By Device',
  };
  public visitorTrendCardHeader: ICardHeaderConfig = {
    title: 'Visitor Trend',
    iconClass: 'fa-bar-chart',
    helpTextKey: constants.helpTextKeys.websiteOverviewVisitorTrendHelp,
    helpTextTitle: 'Visitor Trend',
  };
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Dealer Performance',
    iconClass: 'fa-users',
    helpTextKey: constants.helpTextKeys.websiteOverviewBenchmarkHelp,
    helpTextTitle: 'Benchmark',
    exportName: 'Website Overview - Dealer Performance',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public billboards: IWebsiteOverviewBillboardConfig[] = [
      {
          title: 'Visits',
          secondaryMetricCard: true,
          subtitle: null,
          iconClass: 'fa-users fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'totalVisits',
          metricMOMPropertyName: 'totalVisitsMOM',
          metricYOYPropertyName: 'totalVisitsYOY',
          metricPreviousMonthPropertyName: 'totalVisitsPreviousMonth',
          metricPreviousMTDPropertyName: 'totalVisitsPreviousMTD',
          metricPreviousMTDDisplayOnCurrentMonth: true,
          metricPreviousMTDDisplayHistorical: false,
          metricPreviousMTDLabel: 'Prev. MTD',
          metricPreviousMonthDisplayOnCurrentMonth: true,
          metricPreviousMonthDisplayHistorical: true,
          metricPreviousMonthLabel: 'Prev. Month',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
          trendArrowDrivingPropertyName: 'totalVisitsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: constants.helpTextKeys.websiteOverviewVisitsBillboardHelp,
          helpTextTitle: 'Visits',
          showTrendArrow: true
      },
      {
          title: 'Unique Visitors',
          secondaryMetricCard: true,
          subtitle: null,
          iconClass: 'fa-user-plus fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'uniqueVisits',
          metricMOMPropertyName: 'uniqueVisitsMOM',
          metricYOYPropertyName: 'uniqueVisitsYOY',
          metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMonth',
          metricPreviousMTDPropertyName: 'uniqueVisitsPreviousMTD',
          metricPreviousMTDDisplayOnCurrentMonth: true,
          metricPreviousMTDDisplayHistorical: false,
          metricPreviousMTDLabel: 'Prev. MTD',
          metricPreviousMonthDisplayOnCurrentMonth: true,
          metricPreviousMonthDisplayHistorical: true,
          metricPreviousMonthLabel: 'Prev. Month',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
          trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: constants.helpTextKeys.websiteOverviewUniqueVisitsBillboardHelp,
          helpTextTitle: 'Unique Visitor',
          showTrendArrow: true
      },
      {
          title: 'Engagement',
          secondaryMetricCard: true,
          subtitle: null,
          iconClass: 'fa-commenting fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'engagements',
          metricMOMPropertyName: 'engagementsMOM',
          metricYOYPropertyName: 'engagementsYOY',
          metricPreviousMonthPropertyName: 'engagementsPreviousMonth',
          metricPreviousMTDPropertyName: 'engagementsPreviousMTD',
          metricPreviousMTDDisplayOnCurrentMonth: true,
          metricPreviousMTDDisplayHistorical: false,
          metricPreviousMTDLabel: 'Prev. MTD',
          metricPreviousMonthDisplayOnCurrentMonth: true,
          metricPreviousMonthDisplayHistorical: true,
          metricPreviousMonthLabel: 'Prev. Month',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
          trendArrowDrivingPropertyName: 'engagementsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          secondaryMetricPropertyName: 'engagementRate',
          secondaryMetricLabel: 'Eng. Rate',
          secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
          helpTextKey: constants.helpTextKeys.websiteOverviewEngagementsBillboardHelp,
          helpTextTitle: 'Engagement',
          showTrendArrow: true
      },
      {
          title: 'Actions',
          secondaryMetricCard: true,
          subtitle: null,
          iconClass: 'fa-mouse-pointer fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'actions',
          metricMOMPropertyName: 'actionsMOM',
          metricYOYPropertyName: 'actionsYOY',
          metricPreviousMonthPropertyName: 'actionsPreviousMonth',
          metricPreviousMTDPropertyName: 'actionsPreviousMTD',
          metricPreviousMTDDisplayOnCurrentMonth: true,
          metricPreviousMTDDisplayHistorical: false,
          metricPreviousMTDLabel: 'Prev. MTD',
          metricPreviousMonthDisplayOnCurrentMonth: true,
          metricPreviousMonthDisplayHistorical: true,
          metricPreviousMonthLabel: 'Prev. Month',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
          trendArrowDrivingPropertyName: 'actionsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          secondaryMetricPropertyName: 'actionRate',
          secondaryMetricLabel: 'Action Rate',
          secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
          helpTextKey: constants.helpTextKeys.websiteOverviewActionsBillboardHelp,
          helpTextTitle: 'Actions',
          showTrendArrow: true
      },
  ];
  
  public kpiSheetOptions = {
    overview: [{
      name: 'name',
      label: '',
      isHeader: true
    }, {
      name: 'visits',
      label: 'Visits',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'vdps',
      label: 'VDPs',
      format: constants.formatKeys.localeString
    }, {
      name: 'engagementRate',
      label: 'Engagement Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'bounceRate',
      label: 'Bounce Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'salesServiceOtherBounces',
      label: 'Sales | Service | Other | Bounces',      
    }]
  };

  public kpiCardConfig: IWebsiteOverviewKpiConfig = {
    chartTitle: 'Total Visits vs. Engaged Visits',
    preCutoffChartTitle: 'Total Visits vs. Unique Visits',
    xAxisPropertyName: 'date',
    metrics: {
      'MetricOne': {
          yAxisTitle: 'Visits',
          displayName: 'Total Visits',
          pointLabelFormat: null,
          propertyName: 'totalVisits',
          metricFormatKey: null,
          formatter: null
      },
      'MetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Engaged Visits',
          pointLabelFormat: null,
          propertyName: 'visitsWithEngagements',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricTwo': {
          yAxisTitle: 'Visits',
          displayName: 'Unique Visits',
          pointLabelFormat: null,
          propertyName: 'uniqueVisits',
          metricFormatKey: null,
          formatter: null
      },
      'PreCutoffMetricOne': {
        yAxisTitle: 'Visits',
        displayName: 'Total Visits',
        pointLabelFormat: null,
        propertyName: 'totalVisits',
        metricFormatKey: null,
        formatter: null
      }
    }
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName, 
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName, 
      clickable: false
    },
    // Page Views
    {
      show: true,
      header: 'Page Views',
      columnDef: 'pageViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // New VDPs
    {
      show: true,
      header: 'New VDPs',
      columnDef: 'newInventoryDetailViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Used VDPs
    {
      show: true,
      header: 'Used VDPs',
      columnDef: 'usedInventoryDetailViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Service Views
    {
      show: true,
      header: 'Service Views',
      columnDef: 'servicePageViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Engagement Rate
    {
      show: true,
      header: 'Engagement Rate',
      columnDef: 'engagementRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Avg Time On Site
    {
      show: true,
      header: 'Avg. Time On Site',
      columnDef: 'averageTimeOnSite',
      metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    }
  ];
}
